import React from 'react';

export default function Middd () {
  return (
    <section>
      <div className='container section-main-inn'>
        <div className='d-flex section-main-inn__wr justify-content-center align-items-center align-content-center'>
          <img src="./images/pic4.png" alt="repair" />
          <div className='section-main-inn__top'>
            <h3>
              Innovate with the<br />
              gadget trends
            </h3>
            <p>
              Our gadget repair website is your go-to destination for all your electronic repair needs. Our team of certified technicians can handle repairs for a variety of devices including smartphones, tablets, laptops, and more. We use high-quality parts and ensure quick turnaround times to get your device back to you as soon as possible. Our website also features online booking, repair status updates, and a secure payment system for your convenience. Don't let a broken device slow you down, contact our technicians today!
            </p>
          </div>
        </div>
        <div className='section-main-inn__bot d-flex justify-content-center'>
          <div className='section-main-inn__bot__exp'>
            <div >
              <h5>5 <span>+</span></h5>
              <p>Years Experience</p>
            </div>
            <div>
              <h5>50 <span>k</span></h5>
              <p>Happy Customer</p>
            </div>
          </div>
          <div className='section-main-inn__bot__exp'>
            <div>
              <h5>100 <span>k</span></h5>
              <p>Service Complete</p>
            </div>
            <div>
              <h5>99 <span>%</span></h5>
              <p>Satisfactions</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
