import React from 'react';
export default function Top () {
  return (
    <>
      <span className="services_anchor" id="home"></span>
      <section  >
        <div className="section-bg">
          <div className="section-img container">
            <title>Fast and reliable gadget repair service</title>
            <div className="align-middle">
              <h1 >Fast and <br /> reliable mobile phone <br /> repair service</h1>
            </div>
          </div>
          <div className="position-relative ps-5 p-2 pe-4">
            <h4>Mobile Phone & Computer Repair Services</h4>
          </div>
        </div>
      </section>
    </>
  );
}
