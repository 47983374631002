import React from 'react';

export default function BotEnd () {
  return (
    <section id="contact">
      <div className="bot-end">
        <div className='container bot-end__wrapper'>
          <div className="bot-end__right">
            <img className="position-relative" src="/images/pic2.png" alt="repair" />
          </div>
          <div className="bot-end__left__top ">
            <h5>Contact us</h5>
          </div>
          <div className="bot-end__left__bot">
            <div className="bot__contact">
              <div className="bot__contact__i"><i className="bi bi-telephone"></i></div>
              <div className="bot__contact__h"><h5>CALL US</h5></div>
              <div className="bot__contact__a"><a href="tel:+447474509896">07474509896</a></div>
            </div>
            <div className="bot__contact">
              <div className="bot__contact__i"><i className="bi bi-envelope"></i></div>
              <div className="bot__contact__h"><h5>SEND US AN EMAIL</h5></div>
              <div className="bot__contact__a"><a href="mailto:hello@rfixing.co.uk">hello@rfixing.co.uk</a></div>
            </div>
            <div className="bot__contact">
              <div className="bot__contact__i"><i className="bi bi-geo-alt"></i></div>
              <div className="bot__contact__h"><h5>LOCATION</h5></div>
              <div className="bot__contact__a"><a href="http://" target="_blank" rel="noopener noreferrer">3A Blunt Road,<br /> South Croydon,<br /> CR2 7PA</a></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
