import React from 'react';

export default function Mid () {
  return (
    <section>
      <div className="section-main">
        <div className='container section-main__wrapper'>
          <div className="section-main__left">
          </div>
          <div className="section_main__right">
            <div className="section-main__right__top ">
              <div className="section-main__right__txt">
                <h5>Leading the way in <br /> repair solution</h5>
                <p>Our company specializes in mobile phone repair services. We have a team of experienced technicians who can fix a wide range of issues, such as screen damage, faulty batteries, water damage, and more. We use high-quality replacement parts and ensure that all repairs are done efficiently and effectively. Whether you need a quick fix or a more complex repair, we offer affordable and reliable solutions to get your phone up and running as soon as possible. Contact us today for all your mobile phone repair needs. </p>
              </div>
            </div>
            <div className="section-main__right__bot">
            </div>
          </div>
        </div>
      </div>
    </section >
  );
}
