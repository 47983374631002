import React from 'react';

export default function Testimonials () {
  return (
    <section>
      <div className="section-testimonials container">
        <div className="section-testimonials__title">
          <h5>Testimonials</h5>
        </div>
        <div className="section-testimonials__wrapper">
          <div className="section-testimonials__left_top">
            <img src="./images/start.png" alt="" />
            <p><b style={{color: 'inherit'}}>Thoroughly recommend this business</b><br />
              I took my phone to have the cracked screen fixed. With no appointment, I was expecting to have to return another day. However, the very helpful young man said he could do it in 2 hours.</p>
          </div>
          <div className="section-testimonials__left_bot">
            <img src="./images/start.png" alt="" />
            <p><b style={{color: 'inherit'}}>Great little place</b><br />
              This place is great, reasonably priced and very efficient took less than an hour to replace a new iPhone battery.
              Would highly recommend</p>
          </div>
          <div className="section-testimonials__mid">
            <img src="./images/pic5.png" alt="" />
          </div>
          <div className="section-testimonials__right_top">
            <img src="./images/start.png" alt="" />
            <p><b style={{color: 'inherit'}}>Quick repair</b><br />
              My son broke the screen on his new phone so I booked a repair with Rfixing. The staff were friendly and seemed to know what they were talking about. I explained I needed the phone in a hurry due to an upcoming holiday. I had the phone back within a couple of hours, working and looking great. The whole process was straightforward and the outcome great. I would use this service again and recommend it if you need it.</p>
          </div>
          <div className="section-testimonials__right_bot">
            <img src="./images/start.png" alt="" />
            <p><b style={{color: 'inherit'}}>Prompt and reliable service</b><br />
              Thank you for fixing my smashed ipad,the repair was done within a few hours of me dropping off. I will definitely be using your services again!</p>
          </div>
        </div>
      </div>
    </section>
  );
}
