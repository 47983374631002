import React from 'react';

export default function Services () {
  return (
    <>
      <span className="services_anchor" id="services"></span>
      <section>
        <div className="section-services ">
          <div className="container">
            <div className='section-services__box position-relative d-flex flex-row justify-content-center align-items-center flex-wrap align-content-center' >
              <div className="section-services--txt">
                <h2>We offer a wide variety gadget services</h2>
              </div>
            </div>
            <div className='row position-relative justify-content-around section-services__link'>
              <div className="col-sm colls">
                <div className='section-services__links'>
                  <i className="bi bi-phone"></i>
                  <h5>Mobile Phone</h5>
                </div>
                <div className='section-services__links'>
                  <i className="bi bi-pc-display-horizontal"></i>
                  <h5>Computer Desktop</h5>
                </div>
              </div>
              <div className="col-sm colls">
                <div className='section-services__links'>
                  <i className="bi bi-laptop"></i>
                  <h5>Laptop</h5>
                </div>
                <div className='section-services__links'>
                  <i className="bi bi-camera"></i>
                  <h5>Camera</h5>
                </div>
              </div>
              <div className="col-sm colls">
                <div className='section-services__links'>
                  <i className="bi bi-watch"></i>
                  <h5>Wearable Devices</h5>
                </div>
                <div className='section-services__links'>
                  <i className="bi bi-controller"></i>
                  <h5>Gaming Console</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}
