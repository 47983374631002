import React from 'react';

export default function WhyChooseUs () {
  return (
    <section>
      <div className="section-choose-us">
        <div className='container' >
          <div className='section-choose-us__box'>
            <div className="section-choose-us--txt">
              <h5>Why choose us?</h5>
              <p>Presentations are communication tools that can be used as lectures, speeches, reports, and more.</p>
            </div>
            <div className="section-choose-us__img">
              <img src="/images/pic3.png" alt="working" height='250' />
            </div>
            <div className='section-choose-us__link'>
              <div className='section-choose-us__links'>
                <h3>Professional</h3>
              </div>
              <div className='section-choose-us__links'>
                <h3>Same Day Repairs</h3>
              </div>
              <div className='section-choose-us__links'>
                <h3>Genuine Part</h3>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
