import './App.css';
import './media.css';
import {useEffect} from "react";
import Top from "./components/Top";
import Mid from "./components/Mid";
import WhyChooseUs from "./components/WhyChooseUs";
import Middd from "./components/Middd";
import Services from './components/Services';
import BotEnd from './components/BotEnd';
import Testimonials from "./components/Testimonials";
function App () {
  useEffect(() => {
    const handleContextmenu=e => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return function cleanup () {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);
  return (
    <>
      <header >
        <div className="container">
          <a href="https://www.rfixing.co.uk"><img style={{margin: '10px'}} src="./images/Rfixing.png" alt="logo" width="60px" /></a>
          <div className="nav__right">
            <a href="#home">Home</a>
            <a href="#services">Services</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
      </header>
      <main style={{
        overflow: 'hidden', position: 'relative', top: '95px'
      }}>
        <Top />
        <Mid />
        <Services />
        <Middd />
        <WhyChooseUs />
        <Testimonials />
        <BotEnd />
      </main>
    </>
  );
}

export default App;
